@import "../../../Variables.scss";

.ESelectBox {

  &__inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: .5rem;
    grid-row-gap: .5rem;

    &__option {
      word-break: break-word;
      overflow: hidden;
      min-height: 5rem;
      min-width: 5rem;
      display: flex;
      border-radius: 13px;
      justify-content: center;
      align-items: center;
      padding: .5rem;
      cursor: pointer;
      margin: .5rem;
      text-align: center;
      box-sizing: border-box;
      border: 4px solid $formInputColor;
      color: $formInputColor;

      &.span {
        grid-column: span 2;
        justify-self: center;
        width: 50%;
      }

      &:hover {
        background: $formInputColor;
        color: $buttonColor;
        transition: background .5s;
      }

      &.selected {
        background: $formInputColor;
        color: $backgroundColor;
      }
    }
  }
}