@import 'src/Variables.scss';
#long-menu > .MuiMenu-paper {
	box-shadow: none;
	top: 20;
	margin-top: 25px;
	margin-left: -23px;
}

.MPage {
	display: none;
	box-sizing: border-box;
	padding: 2rem 0;
	width: 100%;

	&__details {
		padding: 0 2rem;
	}

	&__drop-target {
		background-color: transparent;
		position: relative;

		&.isCreator {
			border: 2px dashed #ccc;
			padding: 16px;

			&:hover {
				background: rgba(white, 0.2);
				cursor: grab;
			}
		}

		&.isOver {
			background: rgba(white, 0.2);
		}

		&__field-label {
			font-size: 18px;
			font-family: light;
			overflow: hidden;
			font-weight: 300;
			word-wrap: break-word;
			color: $formInputColor;
		}

		&__button-wrapper {
			display: flex;
			justify-content: end;
			align-items: center;

			&__close-button {
				&.MuiButtonBase-root {
					display: contents;
				}

				&.MuiButtonBase-root .MuiSvgIcon-root {
					fill: $buttonBackground;
				}
			}
		}
	}

	& > * {
		text-align: left;
	}

	&.visible {
		display: flex;
		flex-direction: column;
	}

	&__pages {
		font-size: 20px;
		color: $formTextColorHeadline;
		font-weight: 300;
	}

	&__title {
		color: $formTextColorHeadline;
		font-size: 20px;
		font-weight: bold;
		font-family: Lato;
		text-align: left;
		line-height: 28px;
		overflow: hidden;
		word-wrap: break-word;

		&.centered {
			text-align: center;
		}
	}

	&__description {
		color: $formTextColorContent;
		font-size: 16px;
		font-family: light;
		line-height: 1.6em;
		overflow: hidden;
		word-wrap: break-word;
		margin-top: 1rem;

		&.centered {
			text-align: center;
		}

		& > * {
			line-height: 1.6em;
		}
	}

	&__form {
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		overflow-y: scroll;
		overflow-x: hidden;
		padding: 0 2rem;
		height: 45vh;

		&__answer-overview {
			margin-top: 1rem;
			display: flex;
			flex-direction: column;

			&__headline {
				text-align: center;
				color: $formTextColorHeadline;
			}

			&__answer {
				display: flex;
				justify-content: space-between;
				margin-bottom: .25rem;
				padding-bottom: .25rem;
				border-bottom: 1px solid $formInputColor;


				& > * {
					&:first-of-type {
						color: $primaryColor;
					}

					&:last-of-type {
						color: $formInputColor;
					}
				}


			}
		}

		&.h-0 {
			height: unset;
		}

		&__slider {
			margin-top: 1rem;

			&__wrapper {
				justify-content: center;
				display: flex;
			}
		}

		& > * {
			margin-bottom: 1rem;
		}

		&__selectLabel.MuiFormLabel-root {
			left: -13px;
		}

		&__typography.MuiTypography-root {
			font-size: 1.2rem;
			font-family: light;
			color: $formInputColor;
			overflow: hidden;
			word-wrap: break-word;
		}

		&__formcontrol-label {
			&.MuiFormControlLabel-root {
				border-bottom: none !important;
			}

			& .MuiTypography-root {
				overflow: hidden;
				word-wrap: break-word;

			}
		}


		&__fileupload-button.MuiButtonBase-root {
			color: $buttonColor;
			background: $formInputColor;

			&:hover {
				background: $primaryColor;
				color: $secondColor;
			}
		}
	}

	.MuiFormControl-root {
		width: 100%;
	}

	.MuiSlider-root {
		color: $formInputColor;
		margin-left: .3rem;
		width: calc(100% - 20px);
	}

	.MuiSlider-thumb {

		&.Mui-active {
			box-shadow: none;
		}
	}

	.MuiButtonBase-root.MuiCheckbox-root {
		padding-left: 0;
		color: $formInputColor;

		& > svg {
			fill: $formInputColor;
		}
	}

	.MuiFormControl-root,
	.MuiFormControlLabel-root {
		border-bottom: 1px solid $formInputColor;

		.MuiTypography-root {
			color: $formInputColor;
			font-size: 18px;
			font-family: light !important;
		}
	}

	.MuiInputBase-root {
		color: $formInputColor;
		&.MuiInput-root {
			&::after {
				border-bottom-color: $primaryColor !important;
			}}
	}

	.MuiInputBase-root.SelectInput {
		margin-top: 24px;
	}

	.MuiFormControlLabel-root {
		margin-right: 0;
		margin-left: 0;
		width: 100%;
	}

	.MuiFormLabel-root {
		color: $formInputColor !important;
		font-family: light !important;
		font-size: 18px;
	}

	fieldset .MuiOutlinedInput-notchedOutline {
		legend {
			span {
				color: $formInputColor !important;
			}
		}
	}

	.MuiSelect-select {
		text-align: left;
		font-size: 18px;
		padding-top: 4px;
		padding-bottom: 4px;
	}

	.MuiSwitch-switchBase {
		padding: 9px 0 !important;
		color: $formInputColor !important;
	}

	.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
		background-color: $primaryColor !important;
	}

	.MuiSwitch-root {
		padding: 12px 6px 12px 0;
		width: 40px;
		margin-right: 0.5rem;
	}

	.MuiFormControlLabel-asterisk {
		color: $formInputColor;
	}

	.MuiSlider-valueLabel {
		background: $formInputColor;
	}

	.MuiSlider-valueLabelLabel {
		font-weight: 700;
		color: $backgroundColor;
	}
}
